
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Container, Card } from 'react-bootstrap'
import Alertinfo from '@/components/alert';

function Solution() {
    const [show, setShow] = useState(true);
    let variant = 'success';
    const items = Array.from({ length: 1 }, (_, index) => index + 1);

    // 使用useState定义一个状态变量用于保存本地存储中的值
    const [storedValue, setStoredValue] = useState([]);

    useEffect(() => {
        // 在组件加载时从本地存储中获取值
        const storedData = localStorage.getItem('favorites');
        if (storedData) {
            setStoredValue(JSON.parse(storedData));
        }
    }, []);

    return (
        <Container style={{ marginBottom: '100px', fontSize: '12px' }}>
            <Alertinfo></Alertinfo>
            <Card style={{ marginTop: '15px', }}>
                <Card style={{ borderRadius: '0', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
                    <Card.Title style={{ marginTop: '15px', textAlign: 'left', marginLeft: '20px', }}>

                    </Card.Title>
                </Card>
            </Card>
        </Container>
    );
}

export default Solution;