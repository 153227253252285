
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Tabs, Tab, Form } from 'react-bootstrap'
import BasicTable from '@/components/table';
import ReactPaginate from 'react-paginate';
import { get_records_perpage } from '@/store/rootReducer';


function AddressTab(props) {
    const { addressId } = useParams();
    const [transfer_data, setTransfer_data] = useState([]);
    const [solutions_data, setSolutions_data] = useState([]);
    const [funcs, setFuncs] = useState([]);
    const [record, setRecord] = useState([]);
    const [transfer_data_total, setTransferDataTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const records_perpage = useSelector(get_records_perpage)
    
    const function_table_data = [{'ID':'au1g8ras5xk9ng2h0q2hrf8xdk8599emplxskge0067edm5xmd96vrsmqtjnj',"height":"704,247","Time":"2023-12-01 07:23:27",'Program':'credits.aleo',"Function":"Status"}]
 
    useEffect(() => {
        // 调用异步函数
      getTransfer();
      if (props.addr_type != 'validator') { 
        getSolutions();
      }
    }, [page]); // 传递空数组作为依赖项，确保 useEffect 仅在组件挂载时执行一次
 
    const getTransfer = async() => { 
        let pageObj={
          "page":page,
          "page_size":20,
        }
        const _this = this;
        let data = {
          page:pageObj.page,
          page_size:pageObj.page_size,
          address:addressId
      };
        let url = '/api/v2/aleo/address/transfer/list';
        fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
        })
        .then((response) => {
            if (response.status == 200) {
              return response.json()
            } else {
              return []
            }
        })
      .then(data => {
          // 更新组件状态
          setTransfer_data(data.data.transfers);
          setTransferDataTotal(data.data.count);
          setLoading(false);
        })
          .catch((error) => { 
          setLoading(false);
          })
    }

    const getSolutions = async() => { 
        let pageObj={
          "page":page,
          "page_size":20,
        }
        const _this = this;
        let data = {
          page:pageObj.page,
          page_size:pageObj.page_size,
          address:addressId
      };
        let url = '/api/v2/aleo/address/solutions';
        fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
        })
        .then((response) => {
            if (response.status == 200) {
              return response.json()
            } else {
              return []
            }
        })
      .then(data => {
          // 更新组件状态
          setSolutions_data(data.data.solutions);
          setDataTotal(data.data.count)
          setLoading(false);
        })
          .catch((error) => { 
          setLoading(false);
          })
    }
  
    const handlePageClick = (data) => {
      const selectedPage = data.selected + 1;
      // 处理页码变化
      setPage(selectedPage)
    };
   
  const handleChange = (event) => { 
      setPage(1)
      setPageSize(parseInt(event.target.value));
    }

    return (
      <Card className='border-none'>
             <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="home" title="Transfers">
                    <BasicTable data={transfer_data} is_compress={1}></BasicTable>   
                </Tab>
                {/* <Tab eventKey="profile" title="Coinbase Solutions">
                    <BasicTable data={solutions_data}></BasicTable>   
          </Tab> */}

          {
            props.detail.addr_type != 'validator' &&
            <Tab eventKey="solution" title="Solutions">
              <BasicTable data={solutions_data}></BasicTable>
              <div className='height-36 line-height-36 border-none padding-20-20'>
                <span className='float_left color-black-1D font-14'>共 {dataTotal} 条</span>
                <span className='float_left padding-left'>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={dataTotal/pageSize}
                  previousLabel="<"
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  style={{float:'right'}}
                  // renderOnZeroPageCount={null}
                />
                </span>
                <span className='float_left padding-left'>
                <Form.Select size="lg"
                  value={pageSize}
                  onChange={handleChange}
                  className='float_left height-36 font-14 bg-gray-F2 border-none border-radius-2 padding-6-12 color-black-1D'>
                {records_perpage.map((item)=>{

                  return <option aria-checked value={item}>{item}/page  <span class="icon iconfont" style={{color:'#ffffff'}}> </span> </option>
                })}
                </Form.Select>
                </span>
              </div>
            </Tab>

          }

          <Tab eventKey="Func" title="Functions">
            <BasicTable data={funcs}></BasicTable>
          </Tab>
          

          {props.detail.addr_type === 'validator' && <Tab eventKey="delegators" title="Delegators">
            <BasicTable data={props?.delegators}></BasicTable>
          </Tab>}

          {props.detail.addr_type != 'validator' && <Tab eventKey="Record" title="Bond Record">
            <BasicTable data={record}></BasicTable>
          </Tab>}
       

              
                {/* <Tab eventKey="contact" title="Functions">
                   <BasicTable data={function_table_data}></BasicTable>   
                </Tab> */}
                </Tabs>
        </Card>
  );
}

export default AddressTab;