

export function shortenWalletAddress(walletAddress) {
  // 确保钱包地址至少有一定的长度
  if (walletAddress.length < 10) {
    return walletAddress;
  }

  // 获取地址的前6位和后4位
  const startPart = walletAddress.substring(0, 6);
  const endPart = walletAddress.substring(walletAddress.length - 4);

  // 拼接中间缩略展示部分
  const middlePart = '...';

  // 拼接结果
  const shortenedAddress = `${startPart}${middlePart}${endPart}`;

  return  shortenedAddress;
}
// 将时间戳～转换成具体日期
export function formatDateTime(timeStamp, returnType) {
  var date = new Date();
  date.setTime(timeStamp * 1000);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  if (returnType == 'full') {
   return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  }
  if (returnType == 'y-m-d h:m:s') {
    return y + '-' + m + '-' + d+' '+h + ':' + minute + ':' + second;
   }
  if (returnType == 'y-m-d') {
   return y + '-' + m + '-' + d;
  }
  if (returnType == 'm-d') {
   return m + '-' + d;
  }
  if (returnType == 'h:m') {
   return h + ':' + minute;
  }
  if (returnType == 'h:m:s') {
   return h + ':' + minute + ':' + second;
  }
  return [y, m, d, h, minute, second];
}

export function formatDateRet(dateString,returnType) {
  // 将日期字符串转换为 Date 对象
  const date = new Date(dateString);
  // 使用 toLocaleDateString() 方法提取月份和日期部分
  if( returnType=='m-d'){
    const extractedDate = date.toLocaleDateString('cn', { month: '2-digit', day: '2-digit' });
    return extractedDate.replace('/','-');
  }
  return extractedDate;
}

// 保留 指定位小数
export function filter_num_decimals(value,num) {
  if (!value || value==0 ) return 0;
  let num_float=parseFloat(value).toFixed(num)
  // const formattedNumber= NumberFormatter(num_float,{
  //   thousandSeparator:true,
  //   decimalSeparator:'.'
  // })
  // return formattedNumber;
  return num_float;
}

// 除以10^6，再取6位小数，再将整数部分，3位一个“,”
export function divided_by_power_of_10(value) {
  if (!value || value==0 ) return 0;
  var result = value / Math.pow(10, 6);
  return formatNumber(filter_num_decimals(result,6));
}

// 除以10^6，再取6位小数，
export function divided_by_power_of_10_6(value) {
  if (!value || value == 0) return 0;
  var result = value / Math.pow(10, 6);
  return filter_num_decimals(result, 6);
}

// 数值，取M:百万，k：千
export function format_num(num) {
  
  let power_3 = Math.pow(10, 3)
  let power_6 = Math.pow(10, 6)
  if (num > power_6) {
    return (num / power_6).toFixed(2) + "M"
  } else if (num > power_3) {
    return (num / power_3).toFixed(2) + "K"
  } else {
    return num.toString();
  }
}


// 生成随机数
export function randomNumberInRange(min, max) {
  // 👇️ 获取 min（含）和 max（含）之间的数字
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// 日期相减～找出差值～～用于倒计时 #min(s) #secs
//1719439533000 1721056156404
export function timeStampDifference(startTime,endTime){
  if(startTime==undefined || endTime==undefined){
    return ''
  }
  if(startTime<Math.pow(10,13)){
    startTime=startTime*Math.pow(10,3)
  }
  // console.log("startTime,endTime",startTime,endTime)
  let timeInterval=Math.abs(endTime-startTime)
  let power_3=Math.pow(10,3)//1000

  let hours=Math.floor(parseInt(timeInterval)/power_3/3600)
  let minutes=Math.floor(parseInt(timeInterval)/power_3/60)
  let seconds=Math.floor(parseInt(timeInterval)/power_3)
  //取模处理 60 进制
  minutes=minutes%60;
  seconds=seconds%60;
  // 判断是否为空
  let str=''
  let hours_unit=hours>1?" hours ":" hour ";
  hours=hours?hours+hours_unit:"";
  let minutes_unit=minutes>1?" mins ":" min ";
  minutes=minutes?minutes+minutes_unit:"";
  let seconds_unit=seconds>1?" secs ":" sec "
  seconds=seconds?seconds+seconds_unit:"";
  str=hours+minutes+seconds
  return str=str?str+" ago":'';
  
}

//倒计时
// 更新倒计时函数
export function updateCountdown() {
  // 设置目标时间（这里以10分钟为例）
  const targetTime = new Date().getTime() + 10 * 60 * 1000; // 当前时间 + 10分钟
  const currentTime = new Date().getTime();
  const timeLeft = targetTime - currentTime;
 
  // if (timeLeft >= 0) {
  //   const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  //   const seconds = Math.floor((timeLeft / 1000) % 60);
  //   // 格式化显示
  //   document.getElementById('countdown').innerText = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  // } else {
  //   clearInterval(intervalId);
  //   document.getElementById('countdown').innerText = "00:00";
  // }

  // 显示初始倒计时
// updateCountdown();
 
// 设置间隔ID
// const intervalId = setInterval(updateCountdown, 1000);
}
 

// 数字，格式化
// export function numFormat(value) {
// return numeral(value).format('0,0')
// }

export function nmberFormat(number){
  // console.log(" number>> ",number);
  // 简单的数字格式化函数，可以根据需要进行扩展
  const formatNumber = (num) => {
    // console.log(" num>> ",num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return <span>{formatNumber(number)}</span>;
};

export function blockEpochRatio(block_height){
    // 计算值与360的商，取整，并乘以360
    const result = Math.floor(block_height / 360) * 360;
    // 从原始值中减去上述计算结果
    return block_height - result;
}
//生成随机数
export function generateRandomString (length){
    return Math.random().toString(36).substr(2, length).toLowerCase();
  
}

export function formatNumber(num) {
  num = parseFloat(num)
  return num.toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 });
}

// const number = 150779963984061;
// console.log(formatNumber(number / 1000000)); // Output: 150,779,963.984061




